/* =======================================================================
swiper js
========================================================================== */

/* ==========================================================================
   Swiper modifications
 ========================================================================== */

/* ===================== DEMO Swiper =============================
*/

.main__carousel {

    
.lunchbox {
    position: relative;
    display: flex;
    // width: 1076px;
    width: 100%;
    margin: auto;
}


/* ==========================================================================
   Swiper modifications
 ========================================================================== */

.swiper-container {
    margin-top: 2em;
    /* minor cosmetic */
}

/* df - mobile first  */
.swiper-wrapper {
    /* now pagination is below slides */
    margin-bottom: 3em;
    /* just enough width so slides handing off screen are partially visible */
    width: 73.8%;
}

@media (min-width: 37.5em) {
    .swiper-wrapper {
        width: 100%;
    }
}


/* doubleing up specificity to win over on transform */
/* df - mobile first  */
.swiper-slide.swiper-slide {
    /* a single column layout */
    width: 100%;
}

@media (min-width: 37.5em) {
    .swiper-slide.swiper-slide {
        /* going for a 2 column layout here */
        width: 50%;
        /* centeredSlides set to true */
        /* reposition so first slide appears on left */
        transform: translateX(-50%);
    }
}

@media (min-width: 53em) {
    .swiper-slide.swiper-slide {
        /* upping it to a 3 column layout */
        width: 33.33333333333%;
        /* centeredSlides set to true */
        /* reposition so first slide appears on left */
        transform: translateX(-100%);        
    }
    .lunchbox {
        width: 1076px;
    }
}


/* ==========================================================================
   content in swiper
 ========================================================================== */

.product {
    /* odds are you will use flexbox */
    // display: flex;
    /* center it */
    margin-left: auto;
    margin-right: auto;
    /* adjust the width to add or remove whitespace  */
    width: 89.221%;

    img {
        transition: filter 0.5s;
        border: .8px solid #fff;
      }
      
      .lazy {
        filter: blur(8px);
      }

    /* (B) RESPONSIVE IMAGE */
    .textover img { width: 100%; }
    .textover { max-width: 600px; } /* OPTIONAL */

    /* (C) POSITION CAPTION */
    .textover { position: relative; } /* REQUIRED */
    .textover figcaption {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    /* (D) CAPTION COSMETICS */
    .textover figcaption {
      box-sizing: border-box;
      width: 100%;
      padding: 10px;
      color: white;
      text-align: center;
      background: rgba(0, 0, 0, 0.6);
      border-bottom: .8px solid #fff;
        border-left: .8px solid #fff;
        border-right: .8px solid #fff;
    }
       

    p {
        font-size: 2rem;
    }
}

.photograph {
    /* if parent is flex it will stretch this image */
    // align-self: center;
    display: block;
    height: auto;
    width: 100%;
    @media (min-width: 53em) {
        height: 214px;
        width: 320px;
    }
}




}
