// MEDIA QUERY MANAGER

/*
0 - 600px: Phone
600 - 900px: Tablet 
900 - 1200px: Desktp
[1200 - 1800px]: Desktop
1800px + : Big Desktop
*/


/*
breakpoint arg choices
- phone
- tablet
- desktop

1em = 16px;
i.e. 600px / 16 = 35.7em

ORDER: Base + typeography > general layout + grid > page layout > components
*/

@mixin respond($breakpoint) {
    @if $breakpoint==phone {
        @media (max-width: 35.7em) {
            @content;
        } // 600px
    }
    @if $breakpoint==tablet {
        // /@media (max-width: 56.25em) {
            @media (max-width: 64em) {
            @content;
        } // 900px
    }
    @if $breakpoint==desktop {
        // @media (max-width: 75em), (min-width: 75.1em) {
            @media (min-width: 64em) {
            @content;
        } // 1200px
    }
    // @if $breakpoint==big-desktop {
    //     @media (min-width: 112em) {
    //         @content;
    //     }
    // } // 1800px

    

    @if $breakpoint==MediumImageUrl640 {
        // @media (max-width: 75em), (min-width: 75.1em) {
            @media (min-width: 500px) {
            @content;
        } // 1200px
    }

    @if $breakpoint==MediumImageUrl500 {
        // @media (max-width: 75em), (min-width: 75.1em) {
            @media (max-width: 500px) {
            @content;
        } // 1200px
    }

    @if $breakpoint==SmallImageUrl400 {
        // @media (max-width: 75em), (min-width: 75.1em) {
            @media (max-width: 400px) {
            @content;
        } // 1200px
    }

    @if $breakpoint==SmallImageUrl320 {
        // @media (max-width: 75em), (min-width: 75.1em) {
            @media (max-width: 320px) {
            @content;
        } // 1200px
    }

}