.fadmin {

        .wrapper {
           // background-color: #fff;
            list-style-type: none;
            padding: 0;
            border-radius: 3px;
          }
          .form-row {
            display: flex;
            justify-content: flex-end;
            padding: .5em;
          }
          h1, .form-row > label {
            padding: .5em 1em .5em 0;
            //color: black;
            font-size: 1.8rem;
            // flex: 1;
          }
          .form-row > input {
            // flex: 2;
            width: 3.5rem;
            
          }
          .form-row > input,
          .form-row > button, .flex-left > button  {
            padding: .5em;
          }
          .form-row > button, .flex-left > button {
           background: gray;
           color: white;
           border: 0;
           cursor: pointer;
          }



.flex-container {
    display: flex;
    flex-direction: row;
    h3 {
        font-size: 1.5rem;
        text-decoration: underline;
    }
  }
  
  .flex-left, .flex-left-h {
    width: 50%;    
    margin: 5px;
    padding: 5px;
    a {
        font-size: 1.7rem;
    }
  }

  .flex-left-h {
    width: 100%;  
  }
  
  .flex-right {
    // width: 75%;
    margin: 5px;
    padding: 5px;

  }
  
  .fmt {
    margin-top: 3em;
    border-top: solid 1px #fff;
    padding-top: 3em;
    h2 {
        margin-bottom: 1.5em;
    }
  }

  .fadminbut > button {
    background: gray;
    color: white;
    border: 0;
   }
  
}
