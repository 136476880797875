body {
    background-color: $primary-background-color;
}
.main {
    padding-top: 1.8rem;
    background-color: $primary-background-color;
    padding-bottom: 12em;
    display: flex;
    justify-content: center;
    @include respond(MediumImageUrl640) {
        align-items: center;
    }
    flex-direction: column;
    &__mimage {
        flex-shrink: 0;
        @include respond(MediumImageUrl500) {
            margin-left: 2rem;
            margin-right: 2rem;
        }
        @include respond(MediumImageUrl640) {
            //margin-left: 16rem;
            .swiper2 {
                width: 640px !important;
            }
        }

        img {
            width: 100%;
            border: silver 1px solid;
        }
    }
    &__carousel {
        margin-top: 3.5rem;
        ul {
            display: flex;
            li {
                list-style: none;
                border-color: 1 px solid silver;
                &:not(:last-child) {
                    margin-right: 5.5rem;
                }
            }
            @include respond(tablet) {
                flex-direction: column;
                li {
                    margin-top: 3rem;
                }
            }
        }
    }
    &__player {
        justify-content: space-around;
        width: 100%;
        @media (min-width: 53em) {
            width: 1070px;
        }

        margin-top: 3.4rem;
        background-color: none;
        margin-bottom: 1.7rem;
        ul {
            justify-content: space-around;
        }
        display: flex;

        .swiper-button-next,
        .swiper-button-prev {
            position: relative;
            width: 39px;
            height: 41px;
            display: block;
            margin: 0 !important;
        }

        .swiper-button-next,
        .swiper-button-prev {
            background-image: url("../../../wwwroot/img/right-arrow.png");
            background-repeat: no-repeat;
            background-size: 100% auto;
            background-position: center;
        }

        .swiper-button-prev {
            background-image: url("../../../wwwroot/img/left-arrow.png");
        }

        .swiper-button-next::after,
        .swiper-button-prev::after {
            display: none;
        }

        button {
            //position: relative;
            width: 40px;
            height: 41px;
            display: block;
            background-image: url("../../../wwwroot/img/play-button.png");
            background-repeat: no-repeat;
            background-size: 100% auto;
            background-position: center;
            border: none;
        }

        @include respond(MediumImageUrl500) {
            .swiper-button-next,
            .swiper-button-prev {
                display: none;
            }
            margin-top: 0;
        }
    }
}

.footer {
    font-size: 1.6rem;
    @include respond(tablet) {
        font-size: 2rem;
    }
    background-color: #000;
    line-height: 1.5;
    display: flex;
    justify-content: center;
    padding-top:7rem;
    padding-bottom: 7rem;
    @include respond(tablet) {
        padding-bottom:0;
    }
    
    /* Footer main */
    .ft-main {
        display: flex;
        flex-wrap: wrap;
        width:1000px;
        @include respond(tablet) {
            flex-direction: column;
            width:100%;
            margin:2rem;
            .flogo {
                display: none;;
            }
        }
        ul {
            list-style: none;
            padding-left: 0;
        }
        li {
            margin-top: 1.3em;
        }
        
        a {
            text-decoration: none;
            color: #eee;
        }
        a:hover {
            color: red;
        }
        .ft-title {
            color: #fff;
            padding-bottom: 0.625rem;
        }
        
        .ft-main-item1 {
            flex: 5;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @include respond(tablet) {
             order: 2;
             p {
                font-size: 1.4rem;
             }         
            }
          
        }
        .ft-main-item2, .ft-main-item3  {
            flex: 2;
            @include respond(tablet) {
                margin-top:4em;
            }
        }

        .ft-main-item2 {
            @include respond(tablet) {
                margin-top:0;
            }
        }
        
        .socials {
            display: flex;
            align-items: center;            
            ul {
                display: flex;
                flex-direction: row;                
                li {
                    list-style: none;
                    font-size: 2em;
                    margin-right: .6em;
                    @include respond(tablet) {
                        margin-left: 0;
                    }                
                }
                a:hover {
                    color: red;
                }
            }
            i {
                font-size: .8em;
            }
        }

        form {
            display: inline-flex;
            color: #fff;
    
            input {
                align-self: end;
                margin-left: .2em;
                @include respond(tablet) {
                    margin-left: 0;
                }
            }
    
            @include respond(tablet) {
                display: block; 
                justify-content: center;
            }
        }
    
        input[type="text"] {
            width: 130px;
            box-sizing: border-box;
            border: none;
            font-size: 1.6rem;
            @include respond(tablet) {
                font-size: 2rem;
            }
            background-color: #000;
            -webkit-transition: width 0.4s ease-in-out;
            transition: width 0.4s ease-in-out;
        }
    
        input[type="text"]:focus {
            width: 140px;
            border: none;
        }
        ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #fff;
            opacity: 1; /* Firefox */
        }
    
        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #fff;
        }
    
        ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #fff;
        }
    
        input,
        select,
        textarea {
            color: #fff;
        }
    
        input:focus::placeholder {
            color: transparent;
        }
    
        .search-button {
            background: transparent;
            border: none;
            outline: none;
          }
    }
}
