*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-family: Arial;
    font-style: normal;
    font-variant: normal;
    // This defines what 1rem is
    font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%
    @include respond(desktop) {
        // width < 1200?
        font-size: 62.5%; //  1 rem = 10px; 10px/16px = 62.5%
    }
    @include respond(tablet) {
        // width < 900?
        font-size: 57%; // 1 rem = 8px, 8/14 = 57%
    }
    @include respond(phone) {
        // width < 500?
        font-size: 50%; // 1 rem = 4.8px, 8/16 = 50%
    }
    // @include respond(big-desktop) {
    //     font-size: 75%; // 1rem = 12px, 12/16 = 75%
    // }
}

body {
    color: $primary-text-color;
}

html, body {
    height: 100%;
}

a,
a:visited,
a:hover {
    text-decoration: none;
    color: $primary-text-color;
}