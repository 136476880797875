.header {
    background-color: $primary-background-color;
    nav {
        display: flex;
        justify-content: center;
        padding-top: 3.1rem;
        padding-bottom: 3rem;

        @include respond(tablet) {
            margin-right: 0.5rem;
        }
        .cAnimatedExpander {
            display: none;
        }
        @include respond(tablet) {
            justify-content: space-between;
            // mobile animation
            ul {
                overflow: hidden;
                -webkit-transition: all 1s ease-in-out;
                -moz-transition: all 1s ease-in-out;
                -ms-transition: all 1s ease-in-out;
                -o-transition: all 1s ease-in-out;
                transition: all 1s ease-in-out;
            }
            // hidden and visuallyhidden added by javascript code to hide mobile menu
            .hidden {
                display: none;
            }
            .visuallyhidden {
                opacity: 0;
            }
            // main layout banner removed from mob view
            .header__banner {
                display: none;
            }
            // bill-green logo disappear in mobile view
            .activelogo {
                display: none;
            }
            // open/close mobile munu toggle
            .cAnimatedExpander {
                order: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                height: 2.6rem;
                margin-right: 1rem;
                cursor: pointer;
            }
            .cAnimatedExpander span {
                height: 2px;
                width: 2.6rem;
                background-color: white;
                transition-duration: 1s;
            }
            .cAnimatedExpander span:nth-child(1) {
                top: 0px;
                transform-origin: left center;
            }
            .cAnimatedExpander span:nth-child(2) {
                top: 8px;
                transform-origin: left center;
            }
            .cAnimatedExpander span:nth-child(3) {
                top: 15px;
                transform-origin: left center;
            }
            .cAnimatedExpander.close span:nth-child(1) {
                transform: rotate(43deg);
            }
            .cAnimatedExpander.close span:nth-child(2) {
                width: 0%;
                opacity: 0;
                transition: 0.1s;
            }
            .cAnimatedExpander.close span:nth-child(3) {
                transform: rotate(-43deg);
            }
        }
        @media (max-width: 37.5em) {
            margin-top: 0;
            padding-bottom: 1.1rem;
            padding-top: 1.1rem;
        }
    }
    // header containg banner and menu (banner hidden in mob view)
    // child item of nav flex container with own flex property
    // with items that displayed to none in mobile view
    &__fc {
        display: flex;
        flex-direction: column;
        margin-top: 1.7rem;
        margin-left: 6.3rem;
        width: 800px;
        img {
            width: 100%;
        }
        @include respond(tablet) {
            margin: 0;
            margin-top: 1.5rem;
            width: auto;
        }
    }
    &__banner {
        align-self: flex-start;
    }

    &__logo {
        @media (max-width: 425px) {
            margin-left: 1em;;
            img {
                width: 50%;
            }
        }

    }
    // menu ul
    // ul element has appropriate hidden and visually hidden class' appended
    &__links {
        font-size: 1.4rem;
        font-weight: 700;
        // border: 1px solid white;
        margin-top: 1.7rem;
        list-style: none;
        li {
            display: inline;
            &:not(:last-child) {
                margin-right: 2.9rem;
            }
            &:nth-last-child(2) {
                    margin-right: 1.5em;
                }
            img {          
                width: auto;
            }
        }
        li.active a {
            color: red;
        }
        li a:hover {
            color: red;
        }
        .item {
            order: 1;
        }
        .submenu {
            display: none;
        }
        .submenu-active {
            border-radius: 0;
        }        
        // li class for parent dd menu
        .has-submenu {
            position: relative;
            // mouseover dll nav for desktop
            @include respond(desktop) {
                position: relative;
                // ddl menu
                .submenu {
                    // Pull up to overlap the parent list item very slightly
                    display: block;
                    position: absolute;
                    top: calc(100% - 0.25rem);
                    // Use the left from absolute position to shift the left side
                    left: 182%;
                    // Use translateX to shift the menu 50% of it's width back to the left
                    transform: translateX(-50%);
                    transform: rotateX(-90deg) translateX(-50%);
                    transform-origin: top center;
                    opacity: 0.3;
                    visibility: hidden;
                    transition: 280ms all 120ms ease-out;
                    z-index: 12;
                }
                &:hover,
                &:focus-within {
                    .submenu {
                        opacity: 1;
                        transform: rotateX(0) translateX(-50%);
                        visibility: visible;
                    }
                }
                ul li {
                    float: left;
                    border-top: none;
                    width: 220px;
                    border-right-style: solid;
                    border-bottom-style: solid;
                    border-left-style: solid;
                    border-right-width: 0.5px;
                    border-bottom-width: 0.5px;
                    border-left-width: 0.5px;
                    border-right-color: #aaa;
                    border-bottom-color: #aaa;
                    border-left-color: #aaa;
                    margin-right: -22px;
                    background-color: #212124;
                    padding-left: 0.5rem;
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;
                    line-height: 1.35em;
                }
                li.active {
                    //color: red;
                    background-color: red;
                    a,
                    a:hover {
                        color: #000;
                    }
                }
                ul li:first-child {
                    border-top: solid 0.5px #aaa;
                }
                /* Sub Cat menu link properties */
                ul a {
                    display: block;
                    /* Making sure a element covers whole li area */
                    text-decoration: none;
                    color: #fff;
                    font-size: 12px;
                }
                ul a:hover {
                    color: red;
                }
            }
        }
        // mobile dd menu has-submenu
        @include respond(tablet) {
            margin-left: 3rem;
            display: flex;
            flex-wrap: wrap;
            font-size: 2.4rem;
            li a {
                display: block;
                padding: 15px 5px;
                border: 1px solid white;
            }
            .item.button {
                order: 2;
            }
            .item {
                order: 3;
                width: 100%;
                text-align: left;
                
            }
            .button.secondary {
                /* divider between buttons and menu links */
                border-bottom: 1px #444 solid;
            }
            /* Submenu up from mobile screens */
            .submenu {
                background-color: red;
                li {
                    a:hover {
                        color: #111;
                    }
                }
                li.active {
                    a {
                        color: #000;
                    }
                }
            }
            // set trans animation that is active when is-visible class is appended by javascript
            .submenu {
                display: none;
                height: 0;
                opacity: 0;
                overflow: hidden;
                transition: height 350ms ease-in-out, opacity 750ms ease-in-out;
            }
            .submenu.is-visible {
                display: block;
                height: auto;
                opacity: 1;
            }
            .has-submenu i {
                font-size: 12px;
            }
            // FA carrot icon
            .has-submenu > a::after {
                font-family: "Font Awesome 5 Free";
                font-size: 12px;
                line-height: 16px;
                font-weight: 900;
                margin: 0.6rem;
                content: "\f078";
                color: white;
                position: absolute;
                transform: rotate(0);
                transition: transform 0.5s ease;
            }
            // down class added with javascript
            .has-submenu.down > a {
                background-color: black;
            }
            .has-submenu.down > a::after {
                transform: rotate(-90deg);
            }
            li {
                display: inline;
                &:not(:last-child) {
                    margin-right: 0rem;
                }
                &:nth-last-child(2) {
                    margin-right: 0;
                }
            }
        }
    }
    h1 {
        font-size: 8.1rem;
        margin-left: 0.4rem;
        margin-top: 0.6rem;
    }

    form {
        display: inline-flex;
        color: #fff;

        input {
            align-self: end;
            margin-left: .2em;
            @include respond(tablet) {
                margin-left: 0;
            }
        }

        @include respond(tablet) {
            border: 1px solid #fff;
            display: block;
            padding: 15px 0 15px 1rem;      
            
            justify-content: center;
        }
    }

    input[type="text"] {
        width: 130px;
        box-sizing: border-box;
        border: none;
        font-size: 1.4rem;
        @include respond(tablet) {
            font-size: 2.4rem;
        }
        font-weight: 700;
        background-color: #212124;     
        -webkit-transition: width 0.4s ease-in-out;
        transition: width 0.4s ease-in-out;
    }

    input[type="text"]:focus {
        width: 140px;
        border: none;
    }
    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #fff;
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #fff;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #fff;
    }

    input,
    select,
    textarea {
        color: #fff;
    }

    input:focus::placeholder {
        color: transparent;
    }

    .search-button {
        background: transparent;
        border: none;
        outline: none;
      }
      
    &__socials {
        display: flex;
        align-items: center;
        @include respond(tablet) {
            align-items: flex-start;
            flex-grow: 1;
            justify-content: flex-end;
            margin-right: 3em;
        }
        ul {
            display: flex;
            flex-direction: column;
            @include respond(tablet) {
                flex-direction: row;
            }
            li {
                list-style: none;
                font-size: 2em;
                @include respond(tablet) {
                    margin-left: .5em;
                }                
            }
            a:hover {
                color: red;
            }
        }
    }
}
